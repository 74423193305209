import styled, { css } from "styled-components";

const SIDE_BAR_WIDTH = "257px";

const SIDE_BAR_WIDTH_WHEN_HIDDEN = "42px";

const menuWhenHidden = styled.header`
  position: relative;
  flex: 0 0 ${SIDE_BAR_WIDTH_WHEN_HIDDEN};
  width: ${SIDE_BAR_WIDTH_WHEN_HIDDEN};
  height: 100vh;
  background-color: #233044;
  color: #fff;
  cursor: pointer;
  z-index: 100;

  > .show {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    bottom: 4px;
    right: 10px;
  }
`;

const menu = styled.header`
  position: relative;
  flex: 0 0 ${SIDE_BAR_WIDTH};
  width: ${SIDE_BAR_WIDTH};
  height: 100vh;
  background-color: #233044;
  color: #fff;
  z-index: 100;

  > .hide {
    cursor: pointer;
    display: inline-block;
    position: absolute;
    right: 10px;
    bottom: 10px;
    transform: rotate(180deg);
  }

  > .app-build-info {
    > .built-at {
      text-align: right;
      font-size: 12px;
      margin-right: 20px;
      margin-top: -20px;
      margin-bottom: 20px;
      color: #999999;
    }

    > .hidden {
      display: none;
    }
  }
`;

const MENU_LOGO_HEIGHT = "90px";
const menuLogo = styled.div`
  padding: 20px 10px 30px 34px;
  height: ${MENU_LOGO_HEIGHT};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 18px;
  cursor: pointer;

  > img {
    margin-right: 10px;
  }
`;

const menuTree = styled.div`
  overflow: scroll;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    display: none;
  }
  height: calc(100% - ${MENU_LOGO_HEIGHT});
`;

const menuSection = styled.div`
  margin-bottom: 12px;

  > .label {
    padding: 12px 28px 2px 32px;
    font-size: 12px;
    color: #eeeeee;
    opacity: 0.4;
  }
`;

const mainMenu = styled.div<{ isActive: boolean }>`
  padding: 12px 28px 12px 32px;
  cursor: pointer;
  line-height: 20px;
  &:hover {
    background-color: #1e293a;
  }
  display: flex;
  align-items: center;

  ${({ isActive }) => {
    if (isActive) {
      return css`
        background-color: #1e293a;
      `;
    }
  }}

  > .label {
    color: #fff;
    padding: 0 8px;
    font-size: 13px;
    display: inline-block;

    > a {
      color: #fff;
    }
  }

  > .icon {
    color: #eeeeee;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  > .icon-for-sub-menu-list {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
`;

const subMenuList = styled.div``;

const subMenu = styled.div<{ isActive: boolean }>`
  padding: 12px 28px 12px 32px;
  color: rgba(238, 238, 238, 0.7);
  cursor: pointer;
  font-size: 13px;

  &:hover {
    background-color: #1e293a;
    color: rgb(238, 238, 238);
  }

  ${({ isActive }) => {
    if (isActive) {
      return css`
        background-color: #1e293a;
        color: rgb(238, 238, 238);
      `;
    }
  }}

  > .label {
    margin-left: 30px;
  }
`;

export default {
  menu,
  menuWhenHidden,
  menuLogo,
  menuTree,
  menuSection,
  mainMenu,
  subMenuList,
  subMenu,
};
