import RestartAltIcon from "@mui/icons-material/RestartAlt";
import { Box, IconButton } from "@mui/material";
import Chip from "@mui/material/Chip";

import { styled } from "@mui/material/styles";

const ListItem = styled("li")(({ theme }) => ({
  margin: theme.spacing(0.5),
}));

export default function SearchChipList<T extends string>({
  termSearchTypeOptionList,
  multiSearchWithTermList,
  setMultiSearchWithTermList,
  resetMultiSearchWithTermList,
}: {
  termSearchTypeOptionList: { label: string; value: T }[];
  multiSearchWithTermList: { searchTerm: string; searchType: T }[];
  setMultiSearchWithTermList: (
    value: { searchTerm: string; searchType: T }[]
  ) => void;
  resetMultiSearchWithTermList: () => unknown;
}) {
  const handleChipDelete = (chipData: {
    searchTerm: string;
    searchType: T;
  }) => {
    const filteredMultiSearchWithTermData = multiSearchWithTermList.filter(
      (item) => {
        return (
          item.searchTerm !== chipData.searchTerm &&
          item.searchType !== chipData.searchType
        );
      }
    );

    setMultiSearchWithTermList(filteredMultiSearchWithTermData);
  };

  const getSearchTypeLabel = (value: T) => {
    return (
      termSearchTypeOptionList.find((option) => {
        return value === option.value;
      })?.label || value
    );
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-end",
        flexWrap: "wrap",
        listStyle: "none",
        p: 0.5,
        m: 0,
      }}
      component="ul"
    >
      {multiSearchWithTermList.length > 0 && (
        <IconButton
          aria-label="chip-list-reset"
          onClick={() => resetMultiSearchWithTermList()}
        >
          <RestartAltIcon />
        </IconButton>
      )}

      {multiSearchWithTermList.map((item) => {
        return (
          <ListItem key={item.searchType}>
            <Chip
              label={`${getSearchTypeLabel(item.searchType)} / ${
                item.searchTerm
              }`}
              onDelete={() => handleChipDelete(item)}
            />
          </ListItem>
        );
      })}
    </Box>
  );
}
