import { useFieldArray, useFormContext } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, Typography } from "@mui/material";

import SwitchWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/SwitchWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/TextFieldWithReactHookForm";
import { AdminShipmentItemGroupForm } from "@sellernote/_shared/src/types/forwarding/adminBid";

import SADSTable, { TableBodyRow, TableHeadCell } from "../../../../SADSTable";
import ContainerList from "./ContainerList";

type CellKey = "품명" | "컨테이너" | "위험물" | "CBM" | "중량" | "삭제";

export default function FCLItemForm({
  onShipmentItemCargoUpdateSubmit,
  isImport,
}: {
  onShipmentItemCargoUpdateSubmit: () => void;
  isImport: boolean;
}) {
  const { control, handleSubmit } = useFormContext<{
    itemGroupList: AdminShipmentItemGroupForm[];
  }>();

  const { remove, append, fields } = useFieldArray({
    control,
    name: "itemGroupList",
  });

  const handleRemoveForm = (formIndex: number) => {
    remove(formIndex);
  };

  const headCellList: TableHeadCell<CellKey>[] = [
    {
      cellKey: "품명",
      label: (
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>품명</Grid>
          <Grid item>
            <Button
              variant="contained"
              color="warning"
              onClick={() =>
                append({
                  type: "new",
                  item: {
                    name: "",
                    isDangerous: false,
                    mode: "total",
                  },
                  containerInfo: [
                    {
                      containerType: "20DRY",
                      quantity: 0,
                    },
                  ],
                })
              }
              size="small"
              endIcon={<AddCircleIcon />}
            >
              품목 추가
            </Button>
          </Grid>
        </Grid>
      ),
      width: 300,
    },
    { cellKey: "컨테이너", label: "컨테이너 X 수량", width: 300 },
    { cellKey: "CBM", label: "CBM", align: "right", width: 100 },
    { cellKey: "중량", label: "중량", align: "right", width: 100 },
    { cellKey: "위험물", label: "위험물", width: 100 },
    { cellKey: "삭제", label: "삭제", align: "center", width: 100 },
  ];

  const bodyRowList: TableBodyRow<CellKey>[] = fields.map(
    ({ item, id }, index) => {
      return {
        rowKey: id,
        품명: {
          value: (
            <TextFieldWithReactHookForm
              fullWidth
              name={`itemGroupList.${index}.item.name`}
              control={control}
              required={true}
            />
          ),
        },
        컨테이너: {
          value: (
            <ContainerList itemGroupListIndex={index} isImport={isImport} />
          ),
        },
        CBM: { value: item.cbm || "-" },
        중량: { value: item.weight || "-" },
        위험물: {
          value: (
            <SwitchWithReactHookForm
              label=""
              control={control}
              name={`itemGroupList.${index}.item.isDangerous`}
              defaultValue={false}
            />
          ),
        },
        삭제: {
          value: (
            <Button
              disabled={fields.length === 1}
              color="warning"
              onClick={() => handleRemoveForm(index)}
            >
              <DeleteIcon />
            </Button>
          ),
        },
      };
    }
  );

  return (
    <Grid
      container
      spacing={4}
      direction="row"
      component="form"
      onSubmit={handleSubmit(onShipmentItemCargoUpdateSubmit)}
    >
      <Grid item>
        <Typography variant="h5" component="div">
          화물정보 수정
        </Typography>
      </Grid>

      <Grid item width={"100%"}>
        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>

      <Grid item container justifyContent={"end"}>
        <Grid item>
          <Button variant="contained" type="submit">
            수정내용 저장
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}
