import { useState } from "react";
import { useForm } from "react-hook-form";
import { useQueryClient } from "react-query";
import { Box, Button, Typography } from "@mui/material";

import Modal from "@sellernote/_shared/src/admin-ui/components/Modal";
import TextFieldWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/TextFieldWithReactHookForm";
import useSnackbar from "@sellernote/_shared/src/hooks/admin/useSnackbar";
import ADMIN_BID_QUERY, {
  ADMIN_BID_QUERY_KEY_GEN,
} from "@sellernote/_shared/src/queries/forwarding/admin/ADMIN_BID_QUERY";
import { ExporterInfo } from "@sellernote/_shared/src/types/common/common";
import { ConsolidationItemList } from "@sellernote/_shared/src/types/forwarding/adminBid";
import regEx from "@sellernote/_shared/src/utils/common/regEx";

//TODO: 콘솔 EXW 수출자 정보에 출발지가 추가되어야하고 이에 따라 수정 기능도 추가해야함
function ChangeExporterInfoModal({
  consolidationItem,
  bidId,
}: {
  consolidationItem: ConsolidationItemList;
  bidId: number;
}) {
  const [opensModal, setOpensModal] = useState(false);

  const { handleSnackbarOpen } = useSnackbar();

  const queryClient = useQueryClient();

  const { exporterGroup, exporterInfo, itemList } = consolidationItem;

  const { control, handleSubmit, watch } = useForm<ExporterInfo>({
    defaultValues: {
      ...exporterInfo,
      exporterGroup,
      address: itemList[0]?.address || "",
    },
  });

  const {
    mutate: changeShipmentExporterInfo,
    isLoading,
    ResponseHandler: ResponseHandlerOfChangeShipmentExporterInfo,
  } = ADMIN_BID_QUERY.useChangeShipmentExporterInfo();

  const handleExporterInfoChange = () => {
    changeShipmentExporterInfo(
      {
        bidId,
        exporters: [watch()],
      },
      {
        onSuccess: () => {
          handleSnackbarOpen("수출자 정보가 수정되었습니다.");

          queryClient.invalidateQueries(
            ADMIN_BID_QUERY_KEY_GEN.getAdminBidDetail({
              bidId,
            })
          );

          setOpensModal(false);
        },

        onError: ({ response }) => {
          if (response?.data?.code === 404) {
            // 404일 때 에러메세지로 'shipment not found'가 나와 번역해서 정의함
            handleSnackbarOpen("의뢰를 찾을 수 없습니다.", "error");
            return;
          }

          if (response?.data?.code === 400) {
            handleSnackbarOpen(
              "수출자 정보 수정이 불가능한 상태입니다.",
              "error"
            );
          }
        },
      }
    );
  };

  return (
    <>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        onClick={() => setOpensModal(true)}
      >
        수출자 정보 수정
      </Button>

      <Modal
        isOpened={opensModal}
        handleClose={() => setOpensModal(false)}
        modalBody={
          <>
            <Typography variant="h6">수출자 정보 수정</Typography>
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", rowGap: "16px" }}
              onSubmit={handleSubmit(handleExporterInfoChange)}
            >
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  columnGap: "8px",
                }}
              >
                <TextFieldWithReactHookForm
                  label="회사명"
                  name="companyName"
                  control={control}
                  required
                />

                <TextFieldWithReactHookForm
                  label="담당자"
                  name="personName"
                  control={control}
                  required
                />

                <TextFieldWithReactHookForm
                  label="이메일"
                  name="personEmail"
                  control={control}
                  // pattern을 직접 입력해야 해서 email-validator를 사용하지 않고 따로 email regEx를 추가함
                  pattern={regEx.email}
                  errorMessage="이메일 형식에 맞게 입력해주세요."
                  required
                />

                <TextFieldWithReactHookForm
                  label="연락처"
                  name="personPhone"
                  control={control}
                  required
                />

                <TextFieldWithReactHookForm
                  label="출발지 주소"
                  name="address"
                  control={control}
                  sx={{ gridColumn: "span 4" }}
                />
              </Box>

              <Button disabled={isLoading} type="submit" variant="contained">
                수정
              </Button>
            </Box>
          </>
        }
      />

      {ResponseHandlerOfChangeShipmentExporterInfo}
    </>
  );
}

export default ChangeExporterInfoModal;
