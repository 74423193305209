import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";

import { AdminMainMenu } from "../../../types/forwarding/adminMenu";

import Styled from "./index.styles";

const MENU_ICON_COLOR_STYLE = { opacity: 0.5, color: "#eeeeee" };

export default function MainMenu({ mainMenu }: { mainMenu: AdminMainMenu }) {
  const history = useHistory();
  const { pathname } = history.location;

  const [isOpenedSubMenuList, setIsOpenedSubMenuList] = useState(false);

  const [hasActiveMenu, setHasActiveMenu] = useState(false);

  useEffect(() => {
    if (hasActiveMenu) {
      setIsOpenedSubMenuList(true);
    }
  }, [setIsOpenedSubMenuList, hasActiveMenu]);

  useEffect(() => {
    if (mainMenu.type === "link") {
      if (mainMenu.link.split("?")[0] === pathname) setHasActiveMenu(true);
    }
    if (mainMenu.type === "parent") {
      const isSubMenuActivated = mainMenu.subMenuList.some((subMenu) => {
        return (
          subMenu.type === "link" && subMenu.link.split("?")[0] === pathname
        );
      });

      if (isSubMenuActivated) setHasActiveMenu(true);
    }
  }, [mainMenu, pathname, setHasActiveMenu]);

  return (
    <>
      <Styled.mainMenu
        onClick={() => {
          if (mainMenu.type === "link") {
            history.push(mainMenu.link);
          }

          if (mainMenu.type === "parent") {
            setIsOpenedSubMenuList(!isOpenedSubMenuList);
          }
        }}
        isActive={mainMenu.type === "link" && mainMenu.link === pathname}
      >
        <span className="icon">{mainMenu.icon}</span>

        <span className="label">{mainMenu.label}</span>

        {mainMenu.type === "parent" && (
          <div className="icon-for-sub-menu-list">
            {isOpenedSubMenuList ? (
              <KeyboardArrowUp
                fontSize="small"
                sx={{ ...MENU_ICON_COLOR_STYLE }}
              />
            ) : (
              <KeyboardArrowDown
                fontSize="small"
                sx={{ ...MENU_ICON_COLOR_STYLE }}
              />
            )}
          </div>
        )}
      </Styled.mainMenu>

      {isOpenedSubMenuList && mainMenu.type === "parent" && (
        <Styled.subMenuList>
          {mainMenu.subMenuList.map((subMenu, si) => {
            return (
              <Styled.subMenu
                key={si}
                isActive={
                  subMenu.type === "link" &&
                  subMenu.link.split("?")[0] === pathname
                }
                onClick={() => {
                  if (subMenu.type === "link") {
                    history.push(subMenu.link);
                  }
                }}
              >
                {/* 일단 2 depth까지만 대응 */}
                <div className="label">{subMenu.label}</div>
              </Styled.subMenu>
            );
          })}
        </Styled.subMenuList>
      )}
    </>
  );
}
