import { ChangeEvent } from "react";
import {
  Control,
  Controller,
  FieldValues,
  Path,
  PathValue,
} from "react-hook-form";
import { TextField, TextFieldProps } from "@mui/material";

export type TextFieldWithReactHookFormProps<T extends FieldValues> = {
  name: Path<T>;
  control?: Control<T>;
  required?: boolean;
  pattern?: RegExp;
  errorMessage?: string;
  defaultValue?: PathValue<T, (string | undefined) & Path<T>>;
  handleEffectOnChange?: (
    value?: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => void;
  isOnlyPositiveNumber?: boolean;
  /** 값이 변경될때 공백을 제거할지 여부 */
  needsTrimOnChange?: boolean;
};

export default function TextFieldWithReactHookForm<
  TFieldValues extends FieldValues
>({
  name,
  control,
  required,
  pattern,
  errorMessage,
  defaultValue,
  handleEffectOnChange,
  isOnlyPositiveNumber,
  needsTrimOnChange,
  ...props
}: TextFieldProps & TextFieldWithReactHookFormProps<TFieldValues>) {
  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      control={control}
      rules={{
        required,
        pattern,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...props}
          required={required}
          // react-hook-form에서 단순 type추가로 number를 받지 못해서 추가
          onChange={(e) => {
            if (props?.type === "number") {
              // 달러일 때 소숫점까지 반영 및 음수 방지
              if (isOnlyPositiveNumber) {
                const numberValue = e.target.value
                  ? parseFloat(e.target.value)
                  : "";
                if (typeof numberValue !== "number" || numberValue < 0) {
                  field.onChange("");
                  return;
                }
                field.onChange(numberValue);
                return;
              }
              field.onChange(e.target.value ? parseFloat(e.target.value) : "");
              handleEffectOnChange && handleEffectOnChange(e);
            } else {
              if (needsTrimOnChange) {
                field.onChange(e.target.value.trim());
              } else {
                field.onChange(e.target.value);
              }

              handleEffectOnChange && handleEffectOnChange(e);
            }
          }}
          variant={props.variant ?? "standard"}
          value={props.value ? props.value : field.value ?? ""}
          error={error !== undefined}
          helperText={
            error &&
            (error.type === "required" ? "필수 입력 사항입니다." : errorMessage)
          }
        />
      )}
    />
  );
}
