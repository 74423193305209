import { Dispatch, SetStateAction } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import { Button, Grid, Typography } from "@mui/material";

import NumericFormatWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/NumericFormatWithReactHookForm";
import SelectWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/SelectWithReactHookForm";
import SwitchWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/SwitchWithReactHookForm";
import TextFieldWithReactHookForm from "@sellernote/_shared/src/admin-ui/components/TextFieldWithReactHookForm";
import {
  AdminShipmentItemGroupForm,
  AdminShipmentItemGroupFormForExisting,
  AdminShipmentItemGroupFormForNew,
  ShipmentItem,
  ShipmentItemVolumeUnit,
} from "@sellernote/_shared/src/types/forwarding/adminBid";
import { changeBooleanValueToKr } from "@sellernote/_shared/src/utils/common/etc";
import {
  packageOptionList,
  SIZE_UNIT_OPTION_LIST,
  WEIGHT_UNIT_OPTION_LIST,
} from "@sellernote/_shared/src/utils/common/options";

import SADSTable, { TableBodyRow, TableHeadCell } from "../../../SADSTable";

type CellKey =
  | "품명"
  | "포장유형"
  | "부피"
  | "중량"
  | "수량"
  | "위험물"
  | "냉동/냉장여부"
  | "2단적재"
  | "삭제";

function LCLAndAIRItemForm({
  cargoInputMode,
  setCargoInputMode,
  initialItemGroupList,
  onShipmentItemCargoUpdateSubmit,
}: {
  cargoInputMode: ShipmentItem["mode"];
  setCargoInputMode: Dispatch<SetStateAction<ShipmentItem["mode"]>>;
  initialItemGroupList: AdminShipmentItemGroupFormForExisting[];
  onShipmentItemCargoUpdateSubmit: () => void;
}) {
  const { control, handleSubmit } = useFormContext<{
    itemGroupList: AdminShipmentItemGroupForm[];
  }>();

  const { remove, append, fields, replace } = useFieldArray({
    control,
    name: "itemGroupList",
  });

  const handleRemoveForm = (formIndex: number) => {
    remove(formIndex);
  };

  /**
   * 화물입력 방식(mode)를 바꿀때마다 초기 데이터를 기준으로 화물 데이터를 초기화해준다
   */
  const handleProductsInfoModeChange = () => {
    setCargoInputMode((prev) => {
      const nextState = prev === "total" ? "item" : "total";

      /** '포장별 -> 총'으로 바꾸는 경우 */
      if (prev === "item") {
        const baseItemGroup = initialItemGroupList[0];
        if (!baseItemGroup) {
          throw new Error("Not found baseItemGroup");
        }

        const newItemGroup: AdminShipmentItemGroupFormForNew = {
          type: "new",
          item: {
            ...baseItemGroup.item,
            /** 복수인 경우 품목명은 하나로 합쳐준다. */
            name: initialItemGroupList
              .map(({ item }) => {
                return item.name;
              })
              .toString(),
            weight: 0,
            cbm: 0,
            volumeUnit: "CBM",
            weightUnit: "KG",
            mode: "total",
          },
        };

        /** 총 부피중량일때는 하나의 화물정보만 받는다. */
        const newFieldList: AdminShipmentItemGroupForm[] = [newItemGroup];

        replace(newFieldList);

        return nextState;
      }

      /** '총 -> 포장별'으로 바꾸는 경우 */
      if (prev === "total") {
        const newFieldList = initialItemGroupList.map((itemGroup) => {
          const newItemGroup: AdminShipmentItemGroupFormForNew = {
            type: "new",
            item: {
              ...itemGroup.item,
              weight: 0,
              volumeUnit: "CM" as ShipmentItemVolumeUnit,
              mode: "item",
            },
          };

          return newItemGroup;
        });

        replace(newFieldList);

        return nextState;
      }

      return nextState;
    });
  };

  const headCellList: TableHeadCell<CellKey>[] = [
    {
      cellKey: "품명",
      label: (
        <Grid container justifyContent={"space-between"} alignItems={"center"}>
          <Grid item>품명</Grid>
          {cargoInputMode === "item" && (
            <Grid item>
              <Button
                variant="contained"
                color="warning"
                onClick={() =>
                  append({
                    type: "new",
                    item: {
                      mode: "item",
                      name: "",
                      isDangerous: false,
                      canStack: false,
                      volumeUnit: "CM",
                      weightUnit: "KG",
                      packingType: "boxes",
                      vertical: 0,
                      horizontal: 0,
                      height: 0,
                      weight: 0,
                      quantity: 1,
                    },
                  })
                }
                size="small"
                endIcon={<AddCircleIcon />}
              >
                품목 추가
              </Button>
            </Grid>
          )}
        </Grid>
      ),
      width: 200,
    },
    { cellKey: "포장유형", label: "포장유형", width: 120 },
    {
      cellKey: "부피",
      label:
        cargoInputMode === "total"
          ? "부피 (CBM)"
          : "부피 (가로 | 세로 | 높이 | 단위)",
      width: 240,
    },
    { cellKey: "중량", label: "중량 (중량 | 단위)", width: 120 },
    { cellKey: "수량", label: "수량", width: 100 },
    { cellKey: "위험물", label: "위험물", width: 100 },
    {
      cellKey: "냉동/냉장여부",
      label: "냉동/냉장여부",
      align: "center",
      width: 120,
    },
    { cellKey: "2단적재", label: "2단적재", width: 100 },
    { cellKey: "삭제", label: "삭제", align: "center", width: 100 },
  ];

  const bodyRowList: TableBodyRow<CellKey>[] = fields.map(
    ({ item, id }, index) => {
      return {
        rowKey: id,
        품명: {
          value: (
            <TextFieldWithReactHookForm
              name={`itemGroupList.${index}.item.name`}
              control={control}
              required={true}
              fullWidth
            />
          ),
        },
        포장유형: {
          value: (
            <SelectWithReactHookForm
              name={`itemGroupList.${index}.item.packingType`}
              control={control}
              options={packageOptionList.map((v) => {
                return v.value;
              })}
              fullWidth
              required={true}
            />
          ),
        },
        부피: {
          value:
            cargoInputMode === "total" ? (
              <>
                <NumericFormatWithReactHookForm
                  name={`itemGroupList.${index}.item.cbm`}
                  control={control}
                  required={true}
                  fullWidth
                />
              </>
            ) : (
              <Grid
                container
                alignItems={"center"}
                flexWrap={"nowrap"}
                spacing={1}
              >
                <Grid item>
                  <NumericFormatWithReactHookForm
                    name={`itemGroupList.${index}.item.horizontal`}
                    control={control}
                    required={true}
                  />
                </Grid>
                <Grid item>
                  <NumericFormatWithReactHookForm
                    name={`itemGroupList.${index}.item.vertical`}
                    control={control}
                    required={true}
                  />
                </Grid>
                <Grid item>
                  <NumericFormatWithReactHookForm
                    name={`itemGroupList.${index}.item.height`}
                    control={control}
                    required={true}
                  />
                </Grid>
                <Grid item>
                  <SelectWithReactHookForm
                    name={`itemGroupList.${index}.item.volumeUnit`}
                    control={control}
                    options={SIZE_UNIT_OPTION_LIST.map((option) => {
                      return option.value.toUpperCase();
                    })}
                    fullWidth
                    required={true}
                  />
                </Grid>
              </Grid>
            ),
        },
        중량: {
          value: (
            <Grid
              container
              alignItems={"center"}
              flexWrap={"nowrap"}
              spacing={1}
            >
              <Grid item>
                <NumericFormatWithReactHookForm
                  name={`itemGroupList.${index}.item.weight`}
                  control={control}
                  required={true}
                />
              </Grid>
              <Grid item>
                <SelectWithReactHookForm
                  name={`itemGroupList.${index}.item.weightUnit`}
                  control={control}
                  options={WEIGHT_UNIT_OPTION_LIST.map((v) => {
                    return v.value;
                  })}
                  fullWidth
                  required={true}
                />
              </Grid>
            </Grid>
          ),
        },
        수량: {
          value:
            cargoInputMode === "total" ? (
              "-"
            ) : (
              <NumericFormatWithReactHookForm
                name={`itemGroupList.${index}.item.quantity`}
                control={control}
                required={true}
              />
            ),
        },
        위험물: {
          value: (
            <SwitchWithReactHookForm
              label=""
              control={control}
              name={`itemGroupList.${index}.item.isDangerous`}
              defaultValue={false}
            />
          ),
        },
        "냉동/냉장여부": {
          value: changeBooleanValueToKr(item.needRefrigeration),
        },
        "2단적재": {
          value: (
            <SwitchWithReactHookForm
              label=""
              control={control}
              name={`itemGroupList.${index}.item.canStack`}
              defaultValue={false}
            />
          ),
        },
        삭제: {
          value:
            cargoInputMode === "total" ? (
              "-"
            ) : (
              <Button
                disabled={fields.length === 1}
                color="warning"
                onClick={() => handleRemoveForm(index)}
              >
                <DeleteIcon />
              </Button>
            ),
        },
      };
    }
  );

  return (
    <Grid
      container
      spacing={4}
      direction="row"
      component="form"
      onSubmit={handleSubmit(onShipmentItemCargoUpdateSubmit)}
    >
      <Grid item container justifyContent={"space-between"}>
        <Grid item>
          <Typography variant="h5" component="div">
            화물정보 수정
          </Typography>
        </Grid>

        <Grid item>
          <Button
            variant="contained"
            color="warning"
            onClick={handleProductsInfoModeChange}
            endIcon={<ChangeCircleIcon />}
          >
            {cargoInputMode === "total"
              ? "포장별로 변경"
              : "총 부피/중량으로 변경"}
          </Button>
        </Grid>
      </Grid>

      <Grid item width={"100%"}>
        <SADSTable headCellList={headCellList} bodyRowList={bodyRowList} />
      </Grid>

      <Grid item container justifyContent={"end"}>
        <Grid item>
          <Button variant="contained" type="submit">
            수정내용 저장
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LCLAndAIRItemForm;
