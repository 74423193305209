import { useState } from "react";
import { Button, Grid } from "@mui/material";
import { useAtomValue } from "jotai";

import { APP_NAME } from "@sellernote/_shared/src/constants";
import { FORWARDING_ADMIN_AUTH_SELECTORS } from "@sellernote/_shared/src/jotaiStates/auth";
import { AdminBidDetail } from "@sellernote/_shared/src/types/forwarding/adminBid";
import { checkCanEditBidItem } from "@sellernote/_shared/src/utils/forwarding/admin/adminBid";

import ItemUpdateModal from "../components/ItemUpdateModal";

import FCLItemList from "./FCLItemList";
import LCLAndAIRItemList from "./LCLAndAIRItemList";
import LCLAndAIRTotalList from "./LCLAndAIRTotalList";

export default function GeneralCargoInfo({
  bidDetail,
}: {
  bidDetail: AdminBidDetail;
}) {
  const currentAdminAuthInfo = useAtomValue(
    FORWARDING_ADMIN_AUTH_SELECTORS.CURRENT_FORWARDING_ADMIN_AUTH_INFO
  );

  const [showsItemUpdateModal, setShowsItemUpdateModal] = useState(false);

  const handleItemUpdateModalOpen = () => {
    setShowsItemUpdateModal(true);
  };

  const handleItemUpdateModalClose = () => {
    setShowsItemUpdateModal(false);
  };

  const isLCLAndAIRTotal =
    (bidDetail.freightType === "LCL" || bidDetail.freightType === "AIR") &&
    bidDetail.itemGroupList.map((v) => v.item.mode).includes("total");

  return (
    <>
      <Grid item marginBottom={1}>
        {APP_NAME === "shipda-admin" && (
          <Button
            variant="outlined"
            size="small"
            disabled={checkCanEditBidItem(
              bidDetail.projectStatus,
              currentAdminAuthInfo?.authority
            )}
            onClick={handleItemUpdateModalOpen}
            sx={{ position: "absolute", top: "8px", right: "8px" }}
          >
            화물정보 수정
          </Button>
        )}
      </Grid>

      {bidDetail.freightType === "FCL" && (
        <FCLItemList
          id={bidDetail.id}
          itemGroupList={bidDetail.itemGroupList}
          supply={bidDetail.supply}
          attachmentList={bidDetail.attachments}
        />
      )}

      {(bidDetail.freightType === "LCL" || bidDetail.freightType === "AIR") &&
        !isLCLAndAIRTotal && (
          <LCLAndAIRItemList
            itemGroupList={bidDetail.itemGroupList}
            supply={bidDetail.supply}
            freightType={bidDetail.freightType}
          />
        )}

      {(bidDetail.freightType === "LCL" || bidDetail.freightType === "AIR") &&
        isLCLAndAIRTotal && (
          <LCLAndAIRTotalList
            itemGroupList={bidDetail.itemGroupList}
            supply={bidDetail.supply}
            freightType={bidDetail.freightType}
          />
        )}

      {showsItemUpdateModal && (
        <ItemUpdateModal
          bidDetail={bidDetail}
          onItemUpdateModalClose={handleItemUpdateModalClose}
        />
      )}
    </>
  );
}
