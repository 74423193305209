import { useHistory } from "react-router-dom";
import { Button, Grid, Typography } from "@mui/material";

import useMuiSelect from "@sellernote/_shared/src/hooks/admin/useMuiSelect";
import { TeamMember } from "@sellernote/_shared/src/types/forwarding/adminTeam";

import { saveShipmentCreationInfoToSessionStorage } from "../utils";

import Modal from "../../../Modal";

function TeamMemberSelectModal({
  opensTeamMemberSelectModal,
  setOpensTeamMemberSelectModal,
  teamMemberList,
  teamName,
  teamId,
}: {
  opensTeamMemberSelectModal: boolean;
  setOpensTeamMemberSelectModal: (value: boolean) => void;
  teamMemberList: TeamMember[];
  teamName: string;
  teamId: number;
}) {
  const history = useHistory();

  const getTeamMemberSelectOptions = () => {
    if (!teamMemberList) {
      return [];
    }

    return teamMemberList.map((v) => {
      return { label: `${v.name} (id:${v.userId})`, value: v.userId };
    });
  };

  const {
    selectedValue: teamMemberSelectedValue,
    MuiSelect: TeamMemberSelect,
  } = useMuiSelect({
    options: getTeamMemberSelectOptions(),
    title: "팀멤버 선택",
    fullWidth: true,
  });

  const getUserId = (() => {
    return teamMemberList.find((v) => {
      return v.userId === teamMemberSelectedValue;
    })?.userId;
  })();

  const handleMoveToShipmentCreatePage = () => {
    saveShipmentCreationInfoToSessionStorage({
      teamId: teamId,
      // 버튼 disabled 조건으로 number를 확인
      userId: getUserId as number,
      userName: teamName,
    });

    history.push("/bid/create");
  };

  return (
    <Modal
      isOpened={opensTeamMemberSelectModal}
      handleClose={() => setOpensTeamMemberSelectModal(false)}
      modalBody={
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Typography variant="body2">팀 멤버를 선택하세요.</Typography>
          </Grid>

          <Grid item>{TeamMemberSelect}</Grid>

          <Grid item>
            <Button
              fullWidth
              variant="contained"
              onClick={handleMoveToShipmentCreatePage}
              disabled={!getUserId}
            >
              확인
            </Button>
          </Grid>
        </Grid>
      }
    />
  );
}

export default TeamMemberSelectModal;
