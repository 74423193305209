import React from "react";
import { LogoutOutlined } from "@mui/icons-material";
import { Divider, Tooltip } from "@mui/material";

import Styled from "./index.styles";

export const MENU_ICON_COLOR_STYLE = { opacity: 0.5, color: "#eeeeee" };

function LayoutForAdmin({
  type,
  logo,
  children,
  headerItems,
  onLogout,
  title,
  breadcrumbs,
}: {
  type?: "boful";
  logo: React.ReactNode;
  children: React.ReactNode;
  headerItems: React.ReactNode[];
  onLogout: () => void;
  title?: string;
  breadcrumbs?: string[];
}) {
  return (
    <>
      <Styled.content>
        <Styled.contentHeader>
          {headerItems.map((headerItem) => headerItem)}

          <Tooltip title="로그아웃">
            <div className="logout" onClick={onLogout}>
              <LogoutOutlined sx={{ color: "#9e9e9e", fontSize: "24px" }} />
            </div>
          </Tooltip>
        </Styled.contentHeader>

        <Styled.contentBody type={type}>
          {(title || breadcrumbs) && (
            <Styled.contentBodyHeader>
              {title && <div className="title">{title}</div>}

              {breadcrumbs && (
                <div className="breadcrumbs">
                  {breadcrumbs.map((breadcrumb, i) => {
                    return (
                      <span className="breadcrumb" key={i}>
                        {breadcrumb}
                      </span>
                    );
                  })}

                  {title && (
                    <span className="breadcrumb" key="title">
                      {title}
                    </span>
                  )}
                </div>
              )}

              <Divider sx={{ marginTop: 1, marginBottom: 1 }} />
            </Styled.contentBodyHeader>
          )}
          {children}
        </Styled.contentBody>
      </Styled.content>
    </>
  );
}

export default LayoutForAdmin;
