/**
 - 의뢰 생성 페이지에서 사용할 데이터를 세션스토리지에 저장한다.
   - location.state 또는 jotai는 새로고침 시 데이터가 리셋되기 때문에 sessionStorage로 저장
 */
function saveShipmentCreationInfoToSessionStorage({
  teamId,
  userId,
  userName,
}: {
  teamId: number;
  userId: number;
  userName: string;
}) {
  /** 의뢰 생성을 위한 유저 정보 */
  const userInfo = {
    teamId: teamId,
    userId: userId,
    userName: userName,
  };

  /** 의뢰 생성 초기 기본 값 생성 */
  const bidCreateInfo = {
    bidCreateData: [
      {
        tabLabel: 1,
        bidCreateFormData: { bidType: "import", disabled: false },
        disabled: false,
      },
    ],
    poId: undefined,
    hasPo: false,
    tabsDefaultValue: 0,
  };

  // 객체 데이터를 저장하기 위해 문자열로 변환
  const serializedUserInfo = JSON.stringify(userInfo);
  const serializedBidCreatedInfo = JSON.stringify(bidCreateInfo);

  sessionStorage.setItem(
    "sessionStorageShipmentCreateUserInfo",
    serializedUserInfo
  );
  sessionStorage.setItem(
    "sessionStorageShipmentCreateInfo",
    serializedBidCreatedInfo
  );
}

export { saveShipmentCreationInfoToSessionStorage };
