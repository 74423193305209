import { Grid, SxProps, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

import { ConsolidationItemList } from "../../../../types/forwarding/adminBid";

const columnStyle: SxProps<Theme> = {
  display: "flex",
  flexDirection: "column",
};

/* TODO: 콘솔 EXW일 때 수출자 정보에 출발지가 추가되어야 함 */
function ExporterInfo({
  consolidationItem,
}: {
  consolidationItem: ConsolidationItemList;
}) {
  const { exporterInfo, itemList } = consolidationItem;
  const address = itemList[0]?.address;

  return (
    <Grid
      container
      display="flex"
      justifyContent="space-between"
      sx={{
        padding: "4px 8px",
        backgroundColor: grey[300],
      }}
    >
      <Grid item xs={2} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          회사명
        </Typography>

        <Typography variant="body1" className="sads">
          {exporterInfo.companyName || "-"}
        </Typography>
      </Grid>

      <Grid item xs={2} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          담당자
        </Typography>

        <Typography variant="body1" className="sads">
          {exporterInfo.personName || "-"}
        </Typography>
      </Grid>

      <Grid item xs={2} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          이메일
        </Typography>

        <Typography variant="body1" className="sads">
          {exporterInfo.personEmail || "-"}
        </Typography>
      </Grid>

      <Grid item xs={2} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          연락처
        </Typography>

        <Typography variant="body1" className="sads">
          {exporterInfo.personPhone || "-"}
        </Typography>
      </Grid>

      <Grid item xs={4} sx={columnStyle}>
        <Typography variant="overline" className="sads" color={grey[700]}>
          출발지 주소
        </Typography>

        <Typography variant="body1" className="sads">
          {address || "-"}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default ExporterInfo;
