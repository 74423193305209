import styled from "styled-components";

const content = styled.section`
  height: 100vh;
  flex: 1;
  background-color: #f7f9fc;
  overflow-y: auto;
`;

const CONTENT_HEADER_HEIGHT = "46px";

const contentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: ${CONTENT_HEADER_HEIGHT};
  padding: 0 20px;
  background-color: #fff;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 99;

  > .name {
    margin-right: 20px;
    color: #233044;
    font-size: 16px;
    font-weight: bold;
  }

  > .mypage,
  > .logout {
    display: flex;
    align-items: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
  }
`;

const contentBody = styled.div<{ type?: "boful" }>`
  margin-top: ${CONTENT_HEADER_HEIGHT};
  min-height: calc(100vh - ${CONTENT_HEADER_HEIGHT});
  padding: 16px;
`;

const contentBodyHeader = styled.div`
  > .title {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.87);
    font-weight: 600;
    line-height: 1.25;
  }

  > .breadcrumbs {
    margin-top: 8px;

    > .breadcrumb {
      font-size: 13px;
      color: rgba(0, 0, 0, 0.6);
      font-weight: 400;

      &:not(:last-child) {
        &::after {
          content: ">";
          margin: 0 5px;
        }
      }
    }
  }
`;

export default {
  content,
  contentHeader,
  contentBody,
  contentBodyHeader,
};
